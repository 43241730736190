import * as React from "react"
import {Helmet} from "react-helmet";

const Startscreen = () => {

    const onclick = () => {
        if (typeof window !== `undefined`) {
            window.location.href = '/';
        }
    }

    return (
        <div onClick={onclick} onTouchStart={onclick} onKeyPress={onclick} role="button" tabIndex="-1">
            <Helmet>
                <body className="startscreen"/>
            </Helmet>
            {/* eslint-disable jsx-a11y/media-has-caption */}
            <video id="startscreen" autoPlay loop muted>
                <source src={"/videos/210923_Animation_export.mp4"} type="video/mp4"/>
            </video>
            {/* eslint-enable jsx-a11y/media-has-caption */}
        </div>
    )
}

export default Startscreen
